import React, { Component } from "react";
import Img from "gatsby-image";

export default class Banner extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="banner">
        <Img
          fluid={data.bannerImage.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
        />
        <div className="container">
          <div className="banner-details">
              <span>👋🏻 Hello, I am {data.fullName}</span>
              <span>{data.profession} at <a href={data.companyHomePageLink} target="_blank" rel="noopener noreferrer">{data.companyName}</a> 💻</span>
              <span>Community Lead & Co-founder of <a href={data.communityHomePageLink} target="_blank" rel="noopener noreferrer">{data.community}</a> ☕️</span>
              <span>I am calling myself as a {data.designation} & a {data.designationExtra} 💻</span>
            <ul className="sub-data">
              {data.bannerList.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
            <ul className="social">
              <li>
                <a
                  className="fab fa-twitter"
                  href={data.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                  <a
                    className="fab fa-mastodon"
                    href={data.mastodon}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li>
              <li>
                <a
                  className="fab fa-linkedin-in"
                  href={data.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                <a
                  className="fab fa-github"
                  href={data.github}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                <a
                  className="fab fa-medium-m"
                  href={data.medium}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                <a
                  className="fab fa-stack-overflow"
                  href={data.stackOverflow}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                <a
                  className="fab fa-dev"
                  href={data.dev}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                <a
                  className="fab fa-youtube"
                  href={data.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li>
                 <a
                   className="fa fa-calendar-day"
                   href={data.calendly}
                   target="_blank"
                   rel="noopener noreferrer"
                 ></a>
               </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
